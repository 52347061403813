import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Illustration404 from "../images/illustration404.svg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Rien à voir ici" />
      <section className="bg-grey-lightest">
        <div className="container m-auto flex flex-col md:flex-row max-w-6xl mx-auto py-8">
            <div className="flex flex-col w-full lg:w-1/2 justify-center items-start px-4">
                <h1 className="text-2xl font-bold inline-block my-8">La page demandée n'existe pas.</h1>
                <a href="/" className="block bg-red-light hover:bg-white hover:text-red-light border border-red-light font-bold px-4 py-2 rounded text-white">
                  Retour
                </a>
            </div>
            <div className="w-full lg:w-1/2 lg:py-6"><img src={Illustration404} alt="404 NOT FOUND" className="w-full"/></div>
        </div>
    </section>
    </Layout>
  );
}

export default NotFoundPage;
